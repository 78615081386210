.footer {
  background: $grey-3;
  padding-top: $gutter * 0.6;
  padding-bottom: $gutter * 0.05;
  border-top: 1px solid $grey-2;

  .footer-copyright {
    margin: 15px 0;
    line-height: 1;
    @include media(mobile){
      text-align: center;
    }
  }
  .footer-logo{
    @include media(mobile){
      text-align: center;
    }
  }
  .footer-description {
    font-size: 14px;
  }

  .footer-logotype {
    @include media(tablet) {
      float: right;
      vertical-align: middle;
      margin: 0.45em 0 0 0;
    }
    img {
      width: auto;
      height: 15px;
      vertical-align: bottom;
    }
  }
}
